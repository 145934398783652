import React, { MutableRefObject, useCallback } from 'react';

import { RecEvents } from 'common/RecEvents';

import { Card, CardPropsType } from '../Card';
import { useCardConfig } from '../CardLayout/context';
import { withErrorBoundary } from '../ErrorBoundary/withErrorBoundary';

/**
 * Обертка над обычной карточкой, добавляющей отслеживание и отправку событий для рекомендаций.
 * @param props.className - дополнительный класс;
 * @param props.currentTime - текущее время сервера;
 * @param props.type - тип карточки;
 * @param props.clusterId - id кластера;
 * @param props.index - индекс кластера для топ100;
 * @param props.isAlternativeVersion - флаг альтернативной версии, карточки в вечно зеленом;
 * @param props.onClick - функция клика по карточке;
 * @param props.cardRef - реф карточки;
 * @param props.isLazy - флаг ленивой загрузки изображения.
 */
export const CardWithRcm = withErrorBoundary(function CardWithRcm({
  className,
  currentTime,
  type,
  clusterId,
  isAlternativeVersion,
  index,
  onClick,
  isLazy,
}: CardPropsType) {
  const { getRecBlockType } = useCardConfig();
  const blockInitAtd = getRecBlockType({ index });

  const render = useCallback(
    (ref: MutableRefObject<HTMLDivElement>, onClick: () => void) => (
      <Card
        className={className}
        currentTime={currentTime}
        type={type}
        clusterId={clusterId}
        isAlternativeVersion={isAlternativeVersion}
        index={index}
        onClick={onClick}
        isLazy={isLazy}
        cardRef={ref}
      />
    ),
    [
      className,
      clusterId,
      currentTime,
      index,
      isAlternativeVersion,
      isLazy,
      type,
    ],
  );

  if (blockInitAtd) {
    return (
      <RecEvents
        clusterID={clusterId}
        rcmKey={blockInitAtd}
        position={index}
        contextItemId={blockInitAtd}
        // @ts-expect-error: ¯\_(ツ)_/¯
        render={render}
      />
    );
  }

  return (
    <Card
      className={className}
      currentTime={currentTime}
      type={type}
      clusterId={clusterId}
      isAlternativeVersion={isAlternativeVersion}
      index={index}
      onClick={onClick}
      isLazy={isLazy}
    />
  );
});
